<template>
  <v-row no-gutters class="mt-10">
    <ANotAllowed :notAllowed="notAllowed
      " @close="closeNotAllowed" />
    <v-row class="mx-10 px-10">
      <template v-for="(item, n) in Actions">

        <v-col :key="n" cols="3" class="pa-3 mx-4"
          v-if="item.rolesAllowed.some(role => $store.getters.getRole.includes(role))">

          <v-hover v-slot="{ hover }">
            <v-card @click="actions(item.action)" class="mx-auto my-3" width="220px" height="240px" max-height="240px"
              max-width="220px" :elevation="hover ? 16 : 2" :color="hover ? item.backgroundColor : 'white'">
              <div class="d-flex justify-center pt-6  ">
                <v-card :color="item.backgroundColor" class="pa-4 ">
                  <v-icon background-color="primary lighten-2" size="30" :color="item.iconColor">
                    {{ item.icon }}
                  </v-icon>
                </v-card>
              </div>
              <div class="d-flex justify-center text-wrap">
                <v-card-title style="{font-family: Source Sans Pro;
                            font-size: 28px;
                            font-weight: 600;
                            line-height: 35px;
                            letter-spacing: 0em;
                            text-align: center;
                            }" v-html="item.name">
                  <!-- {{ item.name }} -->
                </v-card-title>
              </div>
            </v-card>
          </v-hover>
        </v-col>
        <v-col v-else :key="n" cols="3" class="pa-3">
          <v-card color="grey" @click="redirectNotAllowed" class="mx-auto my-3" width="200px" height="200px"
            max-height="200px" max-width="200px">
            <div class="d-flex justify-center pt-6">
              <v-icon background-color="grey lighten-2" class="mt-4" size="30" color="black">
                {{ item.icon }}
              </v-icon>
            </div>
            <div class="d-flex justify-center text-wrap">
              <v-card-title style="{font-family: Source Sans Pro;
                            font-size: 28px;
                            font-weight: 600;
                            line-height: 35px;
                            letter-spacing: 0em;
                            text-align: center;
                            }" v-html="item.name">
                <!-- {{ item.name }} -->
              </v-card-title>

            </div>

          </v-card>
        </v-col>

      </template>
    </v-row>
  </v-row>
</template>

<script>
import { mapState } from 'vuex'
import ANotAllowed from '../../components/common/ANotAllowed.vue';
import * as XLSX from 'xlsx';
export default {
  components: {
    ANotAllowed
},
    computed: mapState({
      //
    }),
    props:[
      //
    ],
    data: () => ({
      notAllowed:false,
      role :null,
      Actions:[
        {
          name:'Companies Analysis',
          action:'PageCompaniesListAnalysis',
          backgroundColor:'grey lighten-2',
          iconColor:'grey',
          icon:'bi-gear',
          rolesAllowed:Array.from(["developer"]),
        },
        {
          name:'Renewal',
          action:'PageDeveloperRenewalList',
          backgroundColor:'primary lighten-2',
          iconColor:'primary',
          icon:'bi-person',
          rolesAllowed:Array.from(["developer"]),
        },
        // {
        //   name:'Companies update',
        //   action:'PageCompaniesUpdate',
        //   backgroundColor:'green lighten-2',
        //   iconColor:'green',
        //   icon:'bi-briefcase',
        //   rolesAllowed:Array.from(["developer"]),
        // },
        {
          name:'Task Complete',
          action:'ComponentTaskComplete',
          backgroundColor:'yellow lighten-2',
          iconColor:'yellow',
          icon:'mdi-crown',
          rolesAllowed:Array.from(["developer"]),
        },
        {
          name:'Active Task',
          action:'ComponentActiveTask',
          backgroundColor:'green lighten-2',
          iconColor:'green',
          icon:'mdi-crown',
          rolesAllowed:Array.from(["developer"]),
        },
        {
          name:'Dropzone',
          action:'ComponentAttachmentDropzone',
          backgroundColor:'blue lighten-2',
          iconColor:'blue',
          icon:'mdi-crown',
          rolesAllowed:Array.from(["developer"]),
        },
        {
          name:'Referral',
          action:'PageReferralSettings',
          backgroundColor:'green lighten-3',
          iconColor:'green',
          icon:'bi-gear',
          rolesAllowed:Array.from(["developer"]),
        },
        {
          name:'Server Side Table',
          action:'ComponentTable',
          backgroundColor:'blue lighten-2',
          iconColor:'blue',
          icon:'mdi-crown',
          rolesAllowed:Array.from(['developer']),
        },
        {
          name:'Survey',
          action:'PageSurveyList',
          backgroundColor:'red lighten-3',
          iconColor:'red',
          icon:'bi-gear',
          rolesAllowed:Array.from(["developer"]),
        },
        {
          name:'Excel list',
          action:'PageDeveloperExcelList',
          backgroundColor:'purple lighten-3 text-h6',
          iconColor:'red',
          icon:'bi-gear',
          rolesAllowed:Array.from(["developer"]),
        },
        {
          name:'User Email Matched',
          action:'PageDeveloperUserList',
          backgroundColor:'purple lighten-3 text-h6',
          iconColor:'red',
          icon:'bi-gear',
          rolesAllowed:Array.from(['developer']),
        },
        {
          name:'Vision Key Matched',
          action:'PageDeveloperVisionKey',
          backgroundColor:'purple lighten-3 text-h6',
          iconColor:'red',
          icon:'bi-gear',
          rolesAllowed:Array.from(['developer']),
        },
        // {
        //   name:'Upload Excel Industry',
        //   action:'PageDeveloperUploadExcel',
        //   backgroundColor:'blue lighten-3',
        //   iconColr:'red',
        //   icon:'bi-gear',
        //   rolesAllowed:Array.from(["developer"]),
        // },
        {
          name:'Matched Industry',
          action:'PageDeveloperIndustryMatchList',
          backgroundColor:'green darken-2 text-h6',
          iconColor:'white',
          icon:'bi-gear',
          rolesAllowed:Array.from(["developer"]),
        },
        {
          name:'Subscription History',
          action:'PageDeveloperSubscriptionHistory',
          backgroundColor:'green darken-2 text-h6',
          iconColor:'white',
          icon:'bi-gear',
          rolesAllowed:Array.from(["developer"]),
        },
        {
          name:'Onboarding',
          action:'PageDeveloperUsersOnboarding',
          backgroundColor:'yellow darken-2 text-h6',
          iconColor:'white',
          icon:'bi-gear',
          rolesAllowed:Array.from(["developer"]),
        },
        {
          name:'Reporting',
          action:'PageDeveloperCompaniesReporting',
          backgroundColor:'blue darken-2 text-h6',
          iconColor:'white',
          icon:'bi-gear',
          rolesAllowed:Array.from(["developer"]),
        },
        {
          name:'Features',
          action:'PageFeaturesList',
          backgroundColor:'green darken-2 text-h6',
          iconColor:'white',
          icon:'bi-gear',
          rolesAllowed:Array.from(["developer"]),
        },


        {
          name:'Companies report v2',
          action:'PageCompaniesReportv2',
          backgroundColor:'green darken-2 text-h6',
          iconColor:'white',
          icon:'bi-gear',
          rolesAllowed:Array.from(["developer"]),
        },
        {
          name:'Staff list',
          action:'PageDeveloperStaffList',
          backgroundColor:'green darken-2 text-h6',
          iconColor:'white',
          icon:'bi-gear',
          rolesAllowed:Array.from(['developer']),
        },
        {
          name:'Webinar Attendance',
          action:'PageDeveloperGenerateCertificate',
          backgroundColor:'green darken-2 text-h6',
          iconColor:'white',
          icon:'bi-gear',
          rolesAllowed:Array.from(['developer']),
        },
        {
          name:'Refund',
          action:'PageDeveloperCompaniesRefund',
          backgroundColor:'blue darken-2 text-h6',
          iconColor:'white',
          icon:'bi-gear',
          rolesAllowed:Array.from(['developer']),
        },
        {
          name:'Invoice',
          action:'PageDeveloperInvoice',
          backgroundColor:'green darken-2 text-h6',
          iconColor:'white',
          icon:'bi-gear',
          rolesAllowed:Array.from(['developer']),
        },
        {
          name:'Sales',
          action:'PageDeveloperCompaniesSales',
          backgroundColor:'purple darken-2 text-h6',
          iconColor:'white',
          icon:'bi-gear',
          rolesAllowed:Array.from(['developer']),
        }, 
        {
          name:'HRDC',
          action:'PageDeveloperCompaniesHRDC',
          backgroundColor:'grey darken-2 text-h6',
          iconColor:'white',
          icon:'bi-gear',
          rolesAllowed:Array.from(['developer']),
        },
        { 
          name:'AAE',
          action:'PageDeveloperCompaniesAAE',
          backgroundColor:'purple darken-2 text-h6',
          iconColor:'white',
          icon:'bi-gear',
          rolesAllowed:Array.from(['developer']),
        },
        {
          name:'Account Type',
          action:'PageDeveloperAccountType',
          backgroundColor:'green darken-2 text-h6',
          iconColor:'white',
          icon:'bi-gear',
          rolesAllowed:Array.from(['developer']),
        },
        {
          name:'Survey',
          action:'PageDeveloperSurvey',
          backgroundColor:'green darken-2 text-h6',
          iconColor:'white',
          icon:'bi-gear',
          rolesAllowed:Array.from(['developer'])
        },
        { 
          name:'Funnel',
          action:'PageDeveloperCompaniesFunnel',
          backgroundColor:'red darken-2 text-h6',
          iconColor:'white',
          icon:'bi-gear',
          rolesAllowed:Array.from(['developer']),
        },
        {
          name:'Companies Detail Page v2',
          action:'PageDeveloperCompaniesDetailv2',
          backgroundColor:'green darken-2 text-h6',
          iconColor:'white',
          icon:'bi-gear',
          rolesAllowed:Array.from(['developer']),
        },
        {
          name:'Dropdown',
          action:'PageDeveloperDropdown',
          backgroundColor:'green darken-2 text-h6',
          iconColor:'white',
          icon:'bi-gear',
          rolesAllowed:Array.from(['developer']),
        },
        {
          name:'Comment',
          action:'PageDeveloperComment',
          backgroundColor:'green darken-2 text-h6',
          iconColor:'white',
          icon:'bi-gear',
          rolesAllowed:Array.from(['developer'])
        },
        {
          name:'Download renewal report',
          action:'DownloadExcel',
          backgroundColor:'green darken-2 text-h6',
          iconColor:'white',
          icon:'bi-gear',
          rolesAllowed:Array.from(['developer'])
        },
        {
          name :"Track user cannot register webinar",
          action:'PageTrackUserFailedRegisterWebinar',
          backgroundColor:'red darken-3 text-h6',
          iconColor:'pink',
          icon:'bi-person',
          rolesAllowed:Array.from(['developer'])
        },
        {
          name:"Search keyword",
          action:"PageTrackSearchKeyword",
          backgroundColor:"yellow darken-2 text-h6",
          iconColor:"red",
          icon:"bi-person",
          rolesAllowed:Array.from(["developer"]),
        },
        {
          name:"Coupon Applied",
          action:"PageDeveloperCouponAppliedReport",
          backgroundColor:"blue darken-2 text-h6",
          iconColor:"blue",
          icon:"bi-gear",
          rolesAllowed:Array.from(["developer"]),
        },
        {
          name:"User active / inactive report",
          action:"PageSubscriberActiveInactive",
          backgroundColor:"grey darken-2 text-h6 text-white",
          iconColor:"white",
          icon:"bi-journal-text",
          rolesAllowed:Array.from(["developer"])
        }

       
      ],
      api:{
            isSuccesful:false,
            isError:false,
            isLoading:false,
            error:null,
            url:null,
            success:null,
        },
    }),
    created() {
      this.api.callbackReset = () =>
        {
            this.api.isLoading = true;
            this.api.isError = false;
            this.api.isSuccesful = false;
        };
        this.api.callbackError = (e) =>
        {
            this.api.isError = true;
            this.api.isLoading = false;
            this.api.isSuccesful = false;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) =>
        {
          // link.remove(); 
          if(resp.class=='getRenewalReport'){
            try {
              
               let worksheet = XLSX.utils.json_to_sheet(resp.data);
               let workbook = XLSX.utils.book_new();
               XLSX.utils.book_append_sheet(workbook,worksheet,'Sheet1');
               const binaryData = XLSX.write(workbook,{bookType:'xlsx',type:'array'});
              const blob = new Blob([binaryData], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
              const url = window.URL.createObjectURL(blob);
              let link = document.createElement('a');
              link.href = url;
              link.setAttribute('download','sudsdsbscription_data.xlsx');
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
              URL.revokeObjectURL(link.href);
              
            } catch (error) {
              alert(error);
            }
            }
            this.api.isError = false;
            this.api.isLoading = false;
        }
    },
    mounted() {
      this.role = this.$store.getters.getRole;
    },

  methods: {
    //
    actions(action) {
      if(action ==='DownloadExcel') {
        this.downloadExcel();
        return 1;
      }
      if (action == "PageDeveloperCompaniesList") {
        this.$router.push({ name: action });
      }
      if (action == 'PageAdminStaff') {
        this.$router.push({ name: action, params: { 'staff': 'all' } });
        return 1;
      }
      if (action == 'PageTaskListUser') {
        this.$router.push({ name: action, params: { 'id': this.$store.getters.getUserId } });
        return 1;
      }
      if( action == 'PageTrackUserFailedRegisterWebinar') {
        this.$router.push({name:action})
      }
      this.$router.push({ name: action });
    },
    redirectCompanies() {
      this.$store.commit('updateCurrentTitle', 'Companies');
      this.$router.push({ name: 'PageCompaniesList' });
    },
    redirectSalesPerson() {
      this.$store.commit('updateCurrentTitle', 'Staffs');
      this.$router.push({ name: 'PageAdminSalesperson' })
    },
    redirectStaff() {
      this.$store.commit('updateCurrentTitle', 'Staffs');
      this.$router.push({ name: 'PageAdminSalesperson', params: { "staff": "all" } });
    },
    redirectTeam() {
      this.$store.commit('updateCurrentTitle', 'Teams');
      this.$router.push({
        name: 'PageTeamList'
      })
    },
    downloadExcel() {
      let tempApi = this.$_.clone(this.api);
      tempApi.method = "GET";
      tempApi.url = process.env.VUE_APP_SERVER_API+"/report/renewal";
      tempApi.headers = {
        'Accept':'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      };
      this.$api.fetch(tempApi);
    },
    redirectNotAllowed() {
      this.notAllowed = true;
    },
    closeNotAllowed() {
      this.notAllowed = false;
    },
    redirectUser() {
      this.$store.commit('updateCurrentTitle', 'Users');
      this.$router.push({ name: 'PageUserList' });
    },
    redirectPic() {
      this.$store.commit('updateCurrentTitle', 'Person-in-charge');
      this.$router.push({ name: 'PicList' });
    },
    redirectReceiptInvoice() {
      this.$router.push({ name: 'PageAttachmentList' });
    },
  }
}
</script>